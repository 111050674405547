// This file defines a React Context which keeps track of the authenticated session.

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { AxiosError } from 'axios';
import ory from '@app/lib/OrySdk';
import { Session, Identity } from '@ory/client';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { User } from '@mergetb/api/portal/v1/workspace_types';


interface Context {
  setSession: (session: Session | undefined) => void;
  setAdmin: (admin: boolean) => void;
  syncSession: () => void;
  isAuthenticated: boolean;
  session?: Session;
  identity?: Identity;
  loading: boolean;
  isAdmin: boolean;
  setUser: (u: User | undefined) => void;
  user: User | undefined;
}

export const AuthContext = createContext<Context>({
  setSession: () => {},
  syncSession: () => null,
  setAdmin: () => {},
  isAuthenticated: false,
  session: undefined,
  identity: undefined,
  loading: true,
  isAdmin: false,
  setUser: () => {},
  user: undefined,
});

interface AuthContextProps {
  children: ReactNode;
}

const AuthContextHandler = ({ children }: AuthContextProps) => {
  const [authSession, setAuthSession] = useState<Session | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  const history = useHistory();

  const syncSession = () => {
    return ory
      .toSession()
      .then(({ data: session }) => {
        // This means that the session is still valid! The user is logged in.
        setSession(session);
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          // The user is no longer logged in (hence 401)
        } else {
          // A network or some other error occurred
          console.error(err);
        }

        // Remove the session / log the user out.
        setSession(undefined);
        history.push('/login', { replace: true });
      });
  };

  // Fetches the authentication session if there is one.
  useEffect(() => {
    syncSession();
  }, []);

  const setSession = (session: Session | undefined) => {
    console.log('setting session', session);
    setLoading(false);
    setAuthSession(session);
  };

  const setAdmin = (admin: boolean) => {
    setIsAdmin(admin);
  };

  return (
    <AuthContext.Provider
      value={{
        // The session information.
        // https://github.com/ory/kratos-client-go/blob/master/docs/Session.md
        session: authSession,

        // Move the ID up a level for ease of access.
        // https://github.com/ory/kratos-client-go/blob/master/docs/Identity.md
        identity: authSession?.identity,

        // Is true when the user has a session
        isAuthenticated: Boolean(authSession?.active),

        loading: loading,

        isAdmin: isAdmin,

        // Fetches the session from the server
        syncSession: () => {
          syncSession();
        },

        // Allows to override the session
        setSession: setSession,
        setAdmin: setAdmin,

        setUser: setUser,
        user: user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextHandler;

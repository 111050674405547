import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// We support two "languages": merge and sphere.
import mergeJSON from '@app/locales/merge/translation.json';
import sphereJSON from '@app/locales/sphere/translation.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'merge',
    fallbackLng: 'merge',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      merge: {
        translation: mergeJSON,
      },
      sphere: {
        translation: sphereJSON,
      },
    },
  });

export default i18n;

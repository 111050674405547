import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { AuthContext } from '@app/lib/AuthProvider';
import {
  PageSection,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Bullseye,
  Alert,
  GridItem,
  Grid,
  CardTitle,
} from '@patternfly/react-core';
import {
  GetUserResponse,
  accessModeToJSON,
  userStateToJSON,
  User as PortalUser,
  AccessMode,
  AccessModeUpdate,
  UpdateUserRequest,
} from '@mergetb/api/portal/v1/workspace_types';
import { Table /* data-codemods */, Thead, Tbody, Tr, Td, Th, ActionsColumn, IAction } from '@patternfly/react-table';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { GRPCError } from '@app/lib/error';

type UserProps = {
  username: string | undefined;
};

const User: React.FunctionComponent<UserProps> = ({ username }) => {
  const { uid } = username === undefined ? useParams() : username;
  const { api } = React.useContext(GeneralSettingsContext);

  const [reload, setReload] = React.useState(0);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const st_url = api + '/user/' + uid + '?statusMS=-1';
  const { loading, error, get, response } = useFetch(st_url, options, [reload]);
  const [user, setUser] = React.useState<PortalUser>();
  const [loadError, setLoadError] = React.useState<GRPCError>();

  const reloadUser = () => {
    setReload(reload + 1);
  };

  const getter = (data) => {
    return data.status;
  };

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setUser(GetUserResponse.fromJSON(resp).user);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [load, reload]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Users</BreadcrumbItem>
        <BreadcrumbItem>{uid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id={uid + '-card-id'}>
              <CardTitle id={uid + '-cardheader-id'}>User Details</CardTitle>
              <CardBody>
                {error && !loadError && (
                  <Alert variant="danger" title="Error">
                    Error loading
                  </Alert>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Response Error">
                    <pre>{loadError.message}</pre>
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {user && <UserView user={user} reload={reloadUser} />}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card id="deetsCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={user + '-tst'}
                  url={st_url}
                  getter={getter}
                  ongoingfrequency={5000}
                  completedfrequency={60000}
                  scalingfrequency={1.0 / 10.0}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

interface UserViewProps {
  user: PortalUser | undefined;
  reload: () => void;
}

const UserView: React.FunctionComponent<UserViewProps> = ({ user, reload }) => {
  const { api } = React.useContext(GeneralSettingsContext);
  const { identity, isAdmin } = React.useContext(AuthContext);
  const loggedInUser = identity?.traits.username;

  const cols = {
    username: 'Username',
    name: 'Full Name',
    institution: 'Institution',
    category: 'Category',
    country: 'Country',
    usstate: 'US State',
    state: 'Account State',
    mode: 'Access Mode',
    uid: 'User ID',
    gid: 'Group ID',
    projects: 'Projects',
    experiments: 'Experiments',
    orgs: 'Organizations',
  };

  const actions = React.useMemo(() => {
    // helper function to set the mode.
    const setMode = (uid: string, mode: AccessMode) => {
      const req = UpdateUserRequest.fromJSON({
        username: uid,
        accessMode: {
          value: mode,
        },
      });

      fetch(api + '/user/' + uid, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((resp) => {
          if (resp.ok) {
            reload();
          }
        })
        .catch((e) => console.log('error', e));
    };

    // if there is a logged in user and they match the user being viewed.
    if ((loggedInUser !== undefined && loggedInUser === user.username) || isAdmin) {
      return (u: PortalUser): IAction[] => [
        {
          title: 'Set Access Public',
          onClick: () => setMode(u.username, AccessMode.Public),
        },
        {
          title: 'Set Access Protected',
          onClick: () => setMode(u.username, AccessMode.Protected),
        },
        {
          title: 'Set Access Private',
          onClick: () => setMode(u.username, AccessMode.Private),
        },
      ];
    }

    // no logged in user or logged in user does not match the user we are looking at
    return null;
  }, [reload, api, loggedInUser, user.username]);

  return (
    <React.Fragment>
      <Table aria-label="user-details" variant="compact" borders={false}>
        <Thead>
          <Tr>
            <Th>{cols.username}</Th>
            <Th>{cols.name}</Th>
            <Th>{cols.institution}</Th>
            <Th>{cols.category}</Th>
            <Th>{cols.country}</Th>
            <Th>{cols.usstate}</Th>
            <Th>{cols.state}</Th>
            <Th
              info={{
                tooltip:
                  'Mode deterimines who can see your account details, Ask your portal administrator for the active policy for the exact details.',
                className: 'user-mode-tip',
                tooltipProps: {
                  isContentLeftAligned: true,
                },
              }}
            >
              {' '}
              {cols.mode}
            </Th>
            <Th>{cols.uid}</Th>
            <Th>{cols.gid}</Th>
            <Th>{cols.projects}</Th>
            <Th>{cols.experiments}</Th>
            <Th>{cols.orgs}</Th>
            <Td></Td>
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={0}>
            <Td dataLabel={cols.username}>{user?.username}</Td>
            <Td dataLabel={cols.name}>{user?.name}</Td>
            <Td dataLabel={cols.institution}>{user?.institution}</Td>
            <Td dataLabel={cols.category}>{user?.category}</Td>
            <Td dataLabel={cols.country}>{user?.country}</Td>
            <Td dataLabel={cols.usstate}>{user?.usstate}</Td>
            <Td dataLabel={cols.state}>{userStateToJSON(user?.state)}</Td>
            <Td dataLabel={cols.mode}>{accessModeToJSON(user?.accessMode)}</Td>
            <Td dataLabel={cols.uid}>{user?.uid}</Td>
            <Td dataLabel={cols.gid}>{user?.gid}</Td>
            <Td dataLabel={cols.projects}>
              {Object.keys(user?.projects).map((p, i) => {
                return (
                  <React.Fragment key={i}>
                    <Link to={'/project/' + p}>{p + ' '}</Link>
                    <br />
                  </React.Fragment>
                );
              })}
            </Td>
            <Td dataLabel={cols.experiments}>
              {user?.experiments.length === 0 ? (
                <></>
              ) : (
                user?.experiments.map((e, i) => {
                  const t = e.split('.');
                  return (
                    <React.Fragment key={i}>
                      <Link to={'/project/' + t[1] + '/experiment/' + t[0]}>{e + ' '}</Link>
                      <br />
                    </React.Fragment>
                  );
                })
              )}
            </Td>
            <Td dataLabel={cols.orgs}>
              {Object.keys(user?.organizations).map((o, i) => {
                return (
                  <React.Fragment key={i}>
                    <Link to={'/organization/' + o}>{o}</Link>
                    <br />
                  </React.Fragment>
                );
              })}
            </Td>
            {actions && (
              <Td isActionCell>
                <ActionsColumn items={actions(user)} />
              </Td>
            )}
          </Tr>
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export { User, UserView };

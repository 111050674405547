import * as React from 'react';
import { useContext } from 'react';
import {
  PageSection,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormAlert,
  FormHelperText,
  Alert,
  TextInput,
  TextArea,
  Split,
  SplitItem,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  FlexItem,
  ButtonVariant,
  Flex,
  Tooltip,
  Text,
  TextContent,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { FetchSelect } from '@app/lib/FetchSelect';
import { sortable, headerCol } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { ExperimentCard } from './ExperimentCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';
import { eventEmitter } from '@app/Tutorials/EventEmitter';

// Custom styled tooltip
const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    border: 'none',
    padding: '10px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none', 
  },
});


const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Experiments: React.FunctionComponent = () => {
  const { api } = useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [reloadTrigger, setReloadTrigger] = React.useState(0);
  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  let columns = [
    { 
      title: 'Name', 
      cellTransforms: [headerCol()], 
      transforms: [sortable],
      cellFormatters: [
        (value, { rowData }) => (
          <Link to={`/project/${rowData.project}/experiment/${value}`}>{value}</Link>
        )
      ]
    },
    { 
      title: 'Project', 
      cellTransforms: [headerCol()], 
      transforms: [sortable],
      cellFormatters: [
        (value) => <Link to={`/project/${value}`}>{value}</Link>
      ]
    },
    { title: 'Access Mode', transforms: [sortable] },
    { title: 'Creator', transforms: [sortable] },
  ];


  let actions = [
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        if (rowData && rowData.project && rowData.name) {
          const pid = rowData.project.props ? rowData.project.props.text : rowData.project;
          const eid = rowData.name.props ? rowData.name.props.text : rowData.name;
    
          fetch(api + '/project/' + pid + '/experiment/' + eid, {
            method: 'DELETE',
            credentials: 'include',
          })
            .then((response) => {
              if (response.ok) {
                setReload(reload + 1);
                // Emit the experimentDeleted event
                eventEmitter.emit('experimentDeleted', { experimentName: eid, project: pid });
              } else {
                throw new Error('Failed to delete experiment');
              }
            })
            .catch((error) => {
              console.error('Error deleting experiment:', error);
            });
        } else {
          console.error('Invalid row data for delete action:', rowData);
        }
      },
    },
  ];

  let mapper = React.useCallback((json) => {
    if (!json || !json.experiments || !Array.isArray(json.experiments)) {
      return [];
    }
    return json.experiments.map((x) => ({
      name: {
        title: <Link to={`/project/${x.project}/experiment/${x.name}`}>{x.name}</Link>,
        props: { text: x.name }
      },
      project: {
        title: <Link to={`/project/${x.project}`}>{x.project}</Link>,
        props: { text: x.project }
      },
      access_mode: x.accessMode || '',
      creator: x.creator || '',
      formatted: {
        name: <Link to={`/project/${x.project}/experiment/${x.name}`}>{x.name}</Link>,
        project: <Link to={`/project/${x.project}`}>{x.project}</Link>,
        access_mode: x.accessMode || '',
        creator: x.creator || '',
      },
      experimentLink: `/project/${x.project}/experiment/${x.name}`
    }));
  }, []);
  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '8px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                Experiments
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      An experiment represents the target environment that a user wishes to create on the testbed. An experiment consists of a topology with nodes and links and characteristics of the topology, including OS images and link rates. Experiments are created in the context of a specific project.
                    </Text>
                  </TextContent>
                }
                position="right"
              >
                <Button variant="plain" aria-label="More info for Experiments" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label="New Experiment" onClick={() => setIsModalOpen(true)}>
            New Experiment
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );



  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Experiments</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setReload(reload + 1);
  };

  return (
    <React.Fragment>
      <NewExperiment isOpen={isModalOpen} onClose={toggleModal} />
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="Experiments"
            columns={columns}
            url={api + '/experiment'}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={reloadTrigger}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={ExperimentCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No experiments found" isInline>
            No experiments match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

type NewExperimentProps = {
  isOpen: boolean;
  onClose: () => void;
};

const NewExperiment: React.FunctionComponent<NewExperimentProps> = (props) => {
  const { loading: authLoading, identity } = useContext(AuthContext);
  const { api } = useContext(GeneralSettingsContext);

  const [xp, setXp] = React.useState({ value: '', valid: 'error' });
  const [invalidXp, setInvalidXp] = React.useState('');
  const [pid, setPid] = React.useState({ value: '', valid: 'error' });
  // const [maintainers, setMaintainers] = React.useState([]);
  const [desc, setDesc] = React.useState('');
  
  const submitForm = () => {
    fetch(api + '/project/' + pid.value + '/experiment/' + xp.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        experiment: {
          name: xp.value,
          project: pid.value,
          accessMode: 'Public',
          creator: identity?.traits.username,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          // Emit the experimentCreated event
          eventEmitter.emit('experimentCreated', { experimentName: xp.value, project: pid.value });
        }
        props.onClose();
        setXp({ value: '', valid: 'error' });
        setPid({ value: '', valid: 'error' });
        setDesc('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPidSelect = (val) => {
    setPid({ value: val, valid: val === '' ? 'error' : 'success' });
  };

  const onXpChange = (event) => {
    const val = event.target.value;
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(val) === false) {
      setXp({ value: val, valid: 'error' });
      setInvalidXp(
        'The experiment name must start with a lowercase and only contain lowercase alphanumeric characters'
      );
    } else {
      setInvalidXp('');
      setXp({ value: val, valid: val === '' ? 'error' : 'success' });
    }
  };

  // const onMaintainersSelect = (ms) => {
  //     setMaintainers(ms)
  // }

  function mapProj(json: any): Array<string> {
    return json.projects.map((p) => p.name);
  }

  const notready = xp.valid !== 'success' || pid.valid !== 'success' || desc === '';

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} variant={ModalVariant.medium} aria-label="New Experiment">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Experiment
        </Title>
        <Form isHorizontal>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup fieldId="project" label="Project">
            <FetchSelect label="Project" url={api + '/project'} onSelect={onPidSelect} mapItems={mapProj} />
            {!pid.valid && <FormHelperText>Invalid project selection</FormHelperText>}
          </FormGroup>
          <FormGroup fieldId="creator" label="Creator">
            {authLoading === false ? (
              <TextInput value={identity?.traits.username} aria-label="Creator" isDisabled />
            ) : (
              <Spinner size="sm" aria-label="Loading creator" />
            )}
          </FormGroup>
          <FormGroup fieldId="name" label="Name">
        <TextInput type="text" id="name" value={xp.value} onChange={onXpChange} />
        {xp.valid === 'success' ? (
          <FormHelperText>Valid experiment name</FormHelperText>
        ) : (
          <FormHelperText isError={true}>{invalidXp}</FormHelperText>
        )}
      </FormGroup>
      <FormGroup fieldId="desc" label="Description">
        <TextArea id="desc" value={desc} autoResize={true} onChange={(event) => setDesc(event.target.value)} />
      </FormGroup>
          <FormGroup fieldId="submit">
            <Button
              variant="primary"
              onClick={submitForm}
              isDisabled={xp.valid !== 'success' || pid.valid !== 'success' || desc.trim() === ''}
              isAriaDisabled={xp.valid !== 'success' || pid.valid !== 'success' || desc.trim() === ''}
            >
              {authLoading ? 'Loading User' : 'Submit'}
            </Button>
          </FormGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export { Experiments };
import * as React from 'react';
import { useContext } from 'react';
import {
  Alert,
  AlertGroup,
  AlertVariant,
  PageSection,
  Title,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  FormAlert,
  ActionGroup,
  Button,
  TextInput,
  Split,
  SplitItem,
  Breadcrumb,
  BreadcrumbItem,
  FlexItem,
  Flex,
  ButtonVariant,
  TextContent,
  Text,
  Tooltip,
} from '@patternfly/react-core';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol, cellWidth } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { FetchSelect } from '@app/lib/FetchSelect';
import { useFetch } from 'use-http';
import {
  GetOrganizationsResponse,
  CreateProjectRequest,
  Member,
  Member_Role,
  Member_State,
  GetProjectsResponse,
  member_StateToJSON,
  member_RoleToJSON,
  accessModeToJSON,
  GetEntityTypeConfigurationsResponse,
  AccessMode,
} from '@mergetb/api/portal/v1/workspace_types';
import { ProjectCard } from './ProjectCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';

// Custom styled tooltip
const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    border: 'none',
    padding: '10px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none', 
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Projects: React.FunctionComponent = () => {
  const userview_last = (localStorage.getItem('userview') ?? true) == true;

  const { api } = useContext(GeneralSettingsContext);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const [showNew, setShowNew] = React.useState(false);
  const [newProjName, setNewProjName] = React.useState({ value: '', valid: 'error' });
  const [newProjDesc, setNewProjDesc] = React.useState('');
  const [projOrg, setProjOrg] = React.useState('');
  const [projCat, setProjCat] = React.useState('');
  const [projSubCat, setProjSubCat] = React.useState('');
  const [otherSubCat, setOtherSubCat] = React.useState('');
  const [projSubCats, setProjSubCats] = React.useState(Array<string>);
  const [alerts, setAlerts] = React.useState([]);
  const [invalidName, setInvalidName] = React.useState('');
  const [reload, setReload] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);

  const [reloadTrigger, setReloadTrigger] = React.useState(0);

  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const { data: entTypeData } = useFetch(api + '/configurations/entitytype', options, []);

  const columns = [
    { title: 'Name', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Experiments', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Members', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Category', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(10)] },
    { title: 'Subcategory', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Access Mode', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
    { title: 'Organization', cellTransforms: [headerCol()], transforms: [sortable, cellWidth(15)] },
  ];

  const entityTypes = React.useMemo(() => {
    if (entTypeData) {
      if (Object.prototype.hasOwnProperty.call(entTypeData, 'Types')) {
        const types = GetEntityTypeConfigurationsResponse.fromJSON(entTypeData).Types;
        // Force first category choice in GUI
        if (types.length > 0) {
          setProjCat(types[0].etype);
          return types;
        }
      }
    }
    return undefined;
  }, [entTypeData]);

  const setMode = (pid, mode) => {
    fetch(api + '/project/' + pid, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessMode: {
          value: mode,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('Project ' + pid + ' access mode set to ' + accessModeToJSON(mode), AlertVariant.success);
          setReloadTrigger(prev => prev + 1); // Trigger a reload
        }
        if (resp.status !== 200) {
          addAlert('Error: ' + resp.statusText, AlertVariant.danger);
        }
        return;
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, AlertVariant.danger);
      });
  };

  const actions = [
    {
      title: 'Delete',
      onClick: (event, rowId, rowData) => {
        const projname = rowData.name.props.text;
        fetch(api + '/project/' + projname, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: username,
            name: projname,
          }),
        })
          .then((resp) => {
            if (resp.ok) {
              addAlert('Project ' + projname + ' Deleted', AlertVariant.success);
            }
            if (resp.status !== 200) {
              addAlert('Error: ' + resp.statusText, AlertVariant.danger);
            }
          })
          .catch((error) => {
            console.log(error);
            addAlert(error.message, AlertVariant.danger);
          });
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Set Access Public',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, AccessMode.Public);
      },
    },
    {
      title: 'Set Access Protected',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, AccessMode.Protected);
      },
    },
    {
      title: 'Set Access Private',
      onClick: (_event, _rowId, rowData) => {
        const pid = rowData.name.props.text;
        setMode(pid, AccessMode.Private);
      },
    },
  ];

  const mapper = React.useCallback ((json) => {
    const ps = GetProjectsResponse.fromJSON(json);
    return ps.projects.map((p) => ({
      name: {
        title: <Link to={`/project/${p.name}`}>{p.name}</Link>,
        props: { text: p.name },
      },
      experiments: {
        title: (
          <>
            {p.experiments.map((e, i) => (
              <React.Fragment key={i}>
                <Link to={`/project/${p.name}/experiment/${e}`}>{e}</Link>
                {i < p.experiments.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: p.experiments.join(', ') },
      },
      members: {
        title: (
          <>
            {Object.keys(p.members).map((m, i) => (
              <React.Fragment key={i}>
                <Link to={`/user/${m}`}>{m}</Link>
                {i < Object.keys(p.members).length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </>
        ),
        props: { text: Object.keys(p.members).join(', ') },
      },
      category: p.category,
      subcategory: p.subcategory,
      accessMode: accessModeToJSON(p.accessMode),
      access_mode: accessModeToJSON(p.accessMode), 
      organization: p.organization !== '' ? {
        title: (
          <>
            <Link to={`/organization/${p.organization}`}>{p.organization}</Link>
            {' / ' + member_StateToJSON(p.orgMembership.state)}
          </>
        ),
        props: { text: p.organization + ' / ' + member_StateToJSON(p.orgMembership.state) },
      } : '',
      description: p.description,
    }));
  }, []);

  const toggleNewProj = () => {
    setShowNew(!showNew);
  };

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(reload + 1);
  };

  const addAlert = (t: string, v: AlertVariant) => {
    setAlerts((prev) => {
      return [...prev, { title: t, variant: v }];
    });
    setReload(reload + 1);
  };

  const onProjNameChange = (value) => {
    const re = /^[a-z]([a-z0-9]*[a-z0-9])?$/;
    if (re.test(value) === false) {
      setNewProjName({ value: value, valid: 'error' });
      setInvalidName('The project name must start with a lowercase and only contain lowercase alphanumeric characters');
    } else {
      setInvalidName('');
      setNewProjName({ value: value, valid: value === '' ? 'error' : 'success' });
    }
  };

  const submitForm = () => {
    const req = CreateProjectRequest.fromJSON({
      user: username,
      project: {
        name: newProjName.value,
        description: newProjDesc,
        category: projCat,
        subcategory: otherSubCat !== '' ? otherSubCat : projSubCat,
      },
    });
    req.project.members[username] = {
      role: Member_Role.Creator,
      state: Member_State.Active,
    };

    if (projOrg !== 'None') {
      req.project.organization = projOrg;
      req.project.orgMembership = {
        role: Member_Role.Member,
        state: Member_State.Active,
      };
    }

    fetch(api + '/project/' + newProjName.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((resp) => {
        if (resp.ok) {
          addAlert('New Project ' + newProjName.value + ' Created', 'success');
          setNewProjDesc('');
          setNewProjName({ value: '', valid: 'error' });
          setProjOrg('');
          toggleNewProj();
          return;
        } else {
          return resp.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        addAlert(error.message, 'danger');
      });
  };

  function mapOrgs(json: any): Array<string> {
    const resp = GetOrganizationsResponse.fromJSON(json);
    const ret: Array<string> = ['None'];
    // find orgs in which the user is active in
    resp.organizations.forEach((o) => {
      for (const user in o.members) {
        const m: Member = o.members[user];
        if (user == username && m.state == Member_State.Active) {
          ret.push(o.name);
          break;
        }
      }
    });
    return ret;
  }

  const handleProjCatChange = (value) => {
    setProjCat(value);
    setProjSubCat(''); // Reset subcategory when changing category
    if (entityTypes) {
      const found = entityTypes.find((o) => o.etype === value);
      if (found && found.subtypes) {
        setProjSubCats(found.subtypes);
        if (found.subtypes.length > 0) {
          setProjSubCat(found.subtypes[0]); // Set first subcategory as default
        }
      } else {
        setProjSubCats([]); // Clear subcategories if none found
      }
    }
  };

  const notready = React.useMemo(() => {
    return (
      newProjName.value === '' ||
      newProjName.valid !== 'success' ||
      newProjDesc === '' ||
      projCat === '' ||
      (projSubCats.length > 0 && projSubCat === '') ||
      (projSubCat === 'Other' && otherSubCat === '')
    );
  }, [newProjName, newProjDesc, projCat, projSubCats, projSubCat, otherSubCat]);

  const newModal = (
    <Modal isOpen={showNew} onClose={toggleNewProj} variant={ModalVariant.medium} aria-label="New Project">
      <React.Fragment>
        <Title headingLevel="h1" size="2xl">
          New Project
        </Title>
        <Form>
          {notready && (
            <FormAlert>
              <Alert variant="danger" title="All fields must be filled" aria-live="polite" isInline />
            </FormAlert>
          )}

          <FormGroup label="Name" isRequired>
            <TextInput
              type="text"
              id="name"
              value={newProjName.value}
              onChange={(event, value) => onProjNameChange(value)}
              isValid={newProjName.valid === 'success'}
            />
            {invalidName && <div className="pf-c-form__helper-text pf-m-error">{invalidName}</div>}
          </FormGroup>

          <FormGroup label="Description" isRequired>
            <TextInput
              type="text"
              id="description"
              value={newProjDesc}
              onChange={(event, value) => setNewProjDesc(value)}
            />
          </FormGroup>

          <FormGroup label="Category" isRequired>
            <FormSelect value={projCat} onChange={(_, value) => handleProjCatChange(value)} aria-label="Category">
              <FormSelectOption key="placeholder" value="" label="Select a category" isDisabled />
              {entityTypes && entityTypes.map((e, i) => <FormSelectOption key={i} value={e.etype} label={e.etype} />)}
            </FormSelect>
          </FormGroup>

          {/* Existing form groups for subcategory and other subcategory */}
          {projSubCats.length !== 0 && (
            <FormGroup label="Subcategory">
              <FormSelect value={projSubCat} onChange={(_, value) => setProjSubCat(value)} aria-label="Subcategory">
                {projSubCats.map((e, i) => (
                  <FormSelectOption key={i} value={e} label={e} />
                ))}
              </FormSelect>
            </FormGroup>
          )}
          {projSubCat === 'Other' && (
  <FormGroup label="Other Subcategory">
    <TextInput
      isRequired
      type="text"
      id="othersubcat"
      value={otherSubCat}
      onChange={(event, value) => setOtherSubCat(value)}
    />
  </FormGroup>
)}

          {/* Existing form group for organization */}
          <FormGroup label="Organization">
            <FetchSelect
              label="Organization"
              url={api + '/organization'}
              onSelect={(v) => setProjOrg(v)}
              mapItems={mapOrgs}
            />
          </FormGroup>

          <ActionGroup>
            <Button variant="primary" onClick={submitForm} isDisabled={notready} isAriaDisabled={notready}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </React.Fragment>
    </Modal>
  );

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>Projects</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '8px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                Projects
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      A project represents a type of experimental work to be done on a Merge testbed. Projects are often created to organize the activities of research groups. Users are granted membership in projects by creators and maintainers of those projects.
                    </Text>
                  </TextContent>
                }
                position="right"
              >
                <Button variant="plain" aria-label="More info for Projects" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Flex style={{ gap: '8px' }}>
            <FlexItem>
              <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
                {viewLabel}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button variant="control" aria-label="New Project" onClick={toggleNewProj}>
                New Project
              </Button>
            </FlexItem>
          </Flex>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert variant={AlertVariant[a.var]} title={a.title} timeout={3000} key={i} />
      ))}
    </AlertGroup>
  );

  let url = api + '/project';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {newModal}
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="Projects"
            columns={columns}
            url={url}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={reloadTrigger}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={ProjectCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No projects found" isInline>
            No projects match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { Projects };
import * as React from 'react';
import { useFetch } from 'use-http';
import {
	FormSelect,
	FormSelectOption,
	Alert,
	Spinner
} from '@patternfly/react-core';
import {
	Select,
	SelectOption,
	SelectVariant
} from '@patternfly/react-core/deprecated';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';

type FetchSelectProps = {
  url: string;
  label: string;
  mapItems: (json) => Array<string>; // take the json resp and give back selection items.
  onSelect: (value: string) => void;
};

const FetchSelect: React.FunctionComponent<FetchSelectProps> = (props) => {
  //
  // This generally works but for the fact that the initial selection
  // does not invoke onSelect() so the parent does not get it. The user
  // is forced to choose another option then the first again if the
  // first choice is the desired one.
  //
  const options = {
    credentials: 'include',
    cachePolicy: 'no-cache',
  };
  const { loading, error, data } = useFetch(props.url, options, []);
  const [value, setValue] = React.useState('');
  const conf = React.useContext(GeneralSettingsContext);

  const onSelect = (v) => {
    setValue(v);
    props.onSelect(v);
  };

  React.useEffect(() => {
    if (data) {
      const v = props.mapItems(data)[0];
      onSelect(v);
    }
  }, [data]);

  if (error) {
    const href = conf.auth + '/.login/web/auth/login?return_to=' + window.location.href;
    window.location.href = href;
  }

  return (
    <React.Fragment>
      {error && (
        <Alert variant="danger" title="Error">
          Error loading {props.label}
        </Alert>
      )}
      {loading && <Spinner size="sm" />}
      {data && (
        <FormSelect value={value} onChange={(_event, v) => onSelect(v)} aria-label={props.label} isDisabled={data.length === 0}>
          {props
            .mapItems(data)
            .sort()
            .map((v, i) => (
              <FormSelectOption key={i} value={v} label={v}>
                {v}
              </FormSelectOption>
            ))}
        </FormSelect>
      )}
    </React.Fragment>
  );
};

type FetchSelectMultiProps = {
  url: string;
  label: string;
  mapItems: (json) => Array<string>;
  onSelect: (value: Array<string>) => void;
};

// see https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const FetchSelectMulti: React.FunctionComponent<FetchSelectMultiProps> = (props) => {
  const opts = { credentials: 'include' };
  const { loading, error, data } = useFetch(props.url, opts, []);
  const [values, setValues] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const prevVals = usePrevious(values);

  const onToggle = (o) => {
    setIsOpen(o);
  };

  const onSelect = (event, selection) => {
    // We build the current selecions by hand here so we can pass them
    // back to the parent via props.onSelect().
    let result = [];
    if (values.includes(selection)) {
      // remove selection
      setValues((prevState) => ({ values: prevState.values.filter((item) => item !== selection) }));
      if (prevVals) {
        result = prevVals.filter((item) => item !== selection);
      }
    } else {
      // add selection
      setValues((prevState) => ({ values: [...prevState.values, selection] }));
      if (prevVals) {
        result = [...prevVals, selection];
      } else {
        result = prevVals;
      }
    }

    props.onSelect(result);
  };

  const onClear = () => {
    setValues([]);
    props.onSelect([]);
  };

  return (
    <React.Fragment>
      {error && (
        <Alert variant="danger" title="Error">
          Error loading {props.label}
        </Alert>
      )}
      {loading && <Spinner size="sm" />}
      {data && (
        <Select
          variant={SelectVariant.typeaheadMulti}
          typeAheadAriaLabel={props.label}
          onToggle={(_event, o) => onToggle(o)}
          onSelect={onSelect}
          onClear={onClear}
          selections={values}
          isOpen={isOpen}
          placeholderText={props.label}
        >
          {props
            .mapItems(data)
            .sort()
            .map((v, i) => (
              <SelectOption key={i} value={v} />
            ))}
        </Select>
      )}
    </React.Fragment>
  );
};

export { FetchSelect, FetchSelectMulti };

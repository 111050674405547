// This file contains the actual content of the tutorials. 
//It exports an array of Tutorial objects, each representing a complete tutorial with multiple steps.
// Each tutorial includes:
// 	• Unique identifier
// 	• Title
// 	• Description
// 	• Estimated completion time
// 	• An array of steps
// Each step contains:
// 	• Title
// 	• Content (including instructions)
// 	• Expected actions
// 	• Feedback messages for success and failure



import { Tutorial } from "./Types";

export const tutorials: Tutorial[] = [
  {
    id: 'hello-world',
    title: 'Hello World (Web Interface)',
    description: 'Learn how to create and run your first network experiment in Merge using the web interface "Launch"',
    estimatedTime: '15 minutes',
    steps: [
      {
        title: 'Welcome to Launch',
        content: `
Welcome to Launch! This tutorial will guide you through creating your first network experiment.

In this tutorial, you'll learn to:

1. Create an experiment in a project
2. Design a simple network model
3. Set up and realize the experiment
4. Materialize and interact with your experiment
5. Clean up after you're done

Quick Navigation Tip: Throughout this tutorial, you'll use the left sidebar to navigate between different sections like "Experiments", "Model Editor", and "Realizations".

Let's get started!
        `
      },
      {
        title: 'Creating Your First Experiment',
        content: `
Let's create your first experiment:

1. Click on [LINK:Experiments] in the left sidebar.
2. Click the "New Experiment" button in the top right corner.
3. Fill in the details:
   • Project: Choose your personal project from the dropdown
   • Creator: This will be your account (you can't change this)
   • Name: Give your experiment a name (e.g., "helloworld")
   • Description: Briefly describe your experiment (e.g., "hello world experiment")
4. Click "Submit" to create the experiment.

You'll see your new experiment in the Experiments table.
        `,
        expectedAction: 'experimentCreated',
        successFeedback: "Great job! You've successfully created your first experiment. The experiment '{experimentName}' has been created.",
        failureFeedback: "It looks like you haven't created an experiment yet. Need help finding the 'New Experiment' button?",
      },
      {
        title: 'Designing Your Network Model',
        content: `
Now, let's create a simple network model for your experiment:

1. Click on the [LINK:Model Editor] in the left sidebar.
2. Click "Start from scratch" to load an empty file.
3. Copy and paste the following code into the editor:

[CODE_BLOCK]
from mergexp import *

# Create a network topology object
net = Network('hello-world', addressing==ipv4, routing==static)

# Create three nodes
a,b,c = [net.node(name) for name in ['a', 'b', 'c']]

# Create a link connecting the three nodes
link = net.connect([a,b,c])

# Make this file a runnable experiment
experiment(net)
[/CODE_BLOCK]

4. Click the [ICON:compile] button to check for errors.
5. If compilation is successful, click the [ICON:push] button to add the model to your experiment. 
6. You will have to change your experiment using the drop down menu, the branch will be "Master", and tag will remain Empty.
7. Confirm the push in the dialog that appears.

This simple model creates three nodes (a, b, c) connected by a single link.

Tip: Always compile and confirm before pushing to avoid unnecessary revisions. The compilation process checks for errors and displays the model topology.

Quick Navigation Tip: After pushing, you can click on the experiment name or commit hash to view more details.
`,
expectedAction: 'modelPushed', 
successFeedback: "Great job! You've successfully pushed your model. A new revision has been created in {experimentName} with hash {revisionHash}.", 
failureFeedback: "It looks like you haven't pushed your model yet. Need help finding the 'Push' button?" 
},
      {
        title: 'Realizing the Experiment',
        content: `
Now that we've created and pushed our model, let's realize the experiment:

1. Click on your experiment name (e.g., "helloworld") in the [LINK:Experiments] tab.
2. In the experiment details page, find your model revision in the middle table titled "Models".
3. Click on the Revision hash to go to the revision details page.
4. On the revision details page:
      • Explore different model formats using the revision tabs (Topology, Model, XIR, Graphviz).
      • When ready, click the "Realize Revision" button in the top right corner.
5. Give your realization a name (e.g., "world") and click Submit. The realization process will start. 
6. To view your Realization, click the [LINK:Realizations] tab in the left sidebar.

Quick Navigation Tip: If Realization is not successful, a pop-up message will appear in the top right corner with an error message.

Tip: Realization is asynchronous and may take a few moments. If it fails, click the "Failed" button in the Succeeded column to see why.

Now that we've realized our experiment, we're ready to materialize it!
`,
expectedAction: 'realizationCreated',
successFeedback: "Great job! You've successfully realized your experiment. The realization '{realizationName}' has been created.",
failureFeedback: "It looks like you haven't realized your experiment yet. Need help finding the 'Realize Revision' button?"
},
{
  title: 'Materializing the Experiment',
  content: `
Let's materialize your experiment and prepare to interact with it:

1. Go to the [LINK:Realizations] tab in the left sidebar.
2. Find your realization and click its kebab menu (⋮).
3. Choose "Materialize" from the menu. The materialization process will start.
4. To see the Materialization details and status, click the [LINK:Materializations] tab in the left sidebar. Wait a few moments, and then click the refresh the page by clicking the [LINK:Materializations] hyperlink anywhere on this tutorial page. Do this until Status says "Success".
5. Click on your Materialization name (e.g., "world.helloworld") to view a toggle between your status and details.

Quick Navigation Tip: If Materialization is not successful, a pop-up message will appear in the top right corner with an error message.

Tip: The Status tab shows status and the Details tab shows all materialization tasks (how the experiment nodes and networks were configured).
  `,
  expectedAction: 'materializationCreated',
  successFeedback: "Great job! You've successfully materialized your experiment. The materialization '{materializationName}' has been created.",
  failureFeedback: "It looks like you haven't materialized your experiment yet. Need help finding the 'Materialize' option in the kebab menu?"
},

{
  title: 'Setting Up an XDC',
  content: `
Let's set up an XDC (Experiment Development Container) - the entry point to your experiment:

1. Go to the [LINK:XDCs] tab in the left sidebar.
2. Click "New XDC" in the top right corner.
3. Name your XDC (e.g., "helloxdc") and select your project, then click Submit. Your XDC will appear on the screen.
4. XDC creation is asynchronous, and the "SSH Name" will display N/A until the XDC is ready. Wait a few moments, and then click the refresh the page by clicking the [LINK:XDC] hyperlink anywhere on this tutorial page. Do this until the "SSH name" is populated.
5. Once the XDC is ready, click "Attach" from its kebab menu (:).
6. Select your materialization and click Attach.

Tip: XDCs serve as a home base for experiment development, configuration, and analysis. They can attach to any materialization in the same project.
  `,
  expectedAction: 'xdcCreated',
  successFeedback: "Great job! You've successfully created an XDC named '{xdcName}'. Now, let's attach it to your materialization.",
  failureFeedback: "It looks like you haven't created an XDC yet. Need help finding the 'New XDC' button?",
  additionalAction: 'xdcAttached',
  additionalSuccessFeedback: "Excellent! You've successfully attached the XDC '{xdcName}' to your materialization. You're now ready to interact with your experiment.",
  additionalFailureFeedback: "It looks like you haven't attached your XDC yet. Need help finding the 'Attach' option in the kebab menu?"
},
      {
        title: 'Connecting your Experiment through an XDC',
        content: `
Now that your experiment is materialized and your XDC is attached, let's interact with it:

1. Click the Jupyter link, which will open up Jupyter instance in a new browser tab.
2. From Jupyter, click the 'Terminal' link to open a new terminal.
3.  In the terminal, login as your user by typing:
   \`sudo su - yourusername\`

4. Connect to your nodes via SSH; e.g., ssh a
5. Run any commands you like, such as:
[CODE_BLOCK]
hostname
ping -c 1 b.infra
ping -c 1 b
ping -c 1 c.infra
ping -c 1 c
sudo apt -y update
sudo apt -y install htop
htop
# type ctrl+C to exit the htop program
[/CODE_BLOCK]

Quick Navigation Tip: You can always return to the XDCs tab to find your Jupyter Lab link or SSH information.

        `
      },
      {
        title: 'Cleaning Up',
        content: `
When you're done, it's important to clean up your experiment to free resources for others:

1. Delete your XDC:
- Go to [LINK:XDCs] tab
- Find your XDC and click its kebab menu (⋮)
- Select "Detach"

2. Dematerialize your experiment:
 - Go to [LINK:Materializations] tab
 - Locate your materialization and click its kebab menu (⋮)
 - Choose "Dematerialize"

3. Relinquish your realization:
 - Go to [LINK:Realizations] tab
 - Find your realization and click its kebab menu (⋮)
 - Select "Relinquish"

4. Delete your Experiment:
 - Go to [LINK:Experiments] tab
 - Find your experiment and click its kebab menu (⋮)
 - Select "Delete"

Quick Navigation Tip: You can always recreate your experiment by returning to the Experiments tab and starting the process again.

Tip: Always clean up promptly to keep resources available for others. Merge will automatically free resources when an experiment is deleted, but it's good practice to do it manually.

Congratulations! You've completed your first Merge experiment from start to finish.
        `,
        expectedAction: 'xdcDetached',
        successFeedback: "Great job! You've successfully detached the XDC '{xdcName}' from the materialization. Now, let's continue with the cleanup process.",
        failureFeedback: "It looks like you haven't detached your XDC yet. Need help finding the 'Detach' option in the kebab menu?",
        additionalActions: ['dematerialized', 'relinquished', 'experimentDeleted'],
        additionalFeedback: {
          dematerialized: "Excellent! You've started the dematerialization process for '{materializationName}'. This will free up the resources used by your experiment. Let's move on to the next step.",
          relinquished: "Great work! You've successfully relinquished the realization '{realizationName}'. Now, let's delete the experiment to complete the cleanup process.",
          experimentDeleted: "Congratulations! You've successfully deleted the experiment '{experimentName}'. This completes the cleanup process for your experiment. Congratulations on finishing the tutorial!"
        }
      }
    ]
  }
];
import * as React from 'react';
import {
  Toolbar,
  ToolbarItem,
  ToolbarContent,
  InputGroup,
  Alert,
  Spinner,
  SearchInput,
  EmptyState,
  Bullseye,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateHeader,
  InputGroupItem,
  Title,
} from '@patternfly/react-core';
import { SortByDirection } from '@patternfly/react-table';
import { Table, TableHeader, TableBody } from '@patternfly/react-table/deprecated';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import CubesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';
import { sortable, headerCol } from '@patternfly/react-table';
import { Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import { css } from '@patternfly/react-styles';
import { KebabMenu } from './KebabMenu';

interface ActionListToolbarProps {
  kind: string;
  setSearch: (value: string) => void;
  searchTerm: string;
}

const ActionListToolbar: React.FunctionComponent<ActionListToolbarProps> = ({ kind, setSearch, searchTerm }) => {
  const debouncedSearch = React.useMemo(() => debounce((value: string) => setSearch(value), 300), [setSearch]);

  const handleClear = React.useCallback(() => {
    setSearch('');
  }, [setSearch]);

  return (
    <Toolbar id="toolbar">
      <ToolbarContent>
        <ToolbarItem variant="search-filter">
          <InputGroup>
            <SearchInput
              name="searchinput"
              id={kind + 'searchinput'}
              type="search"
              aria-label={`Search ${kind}`}
              placeholder={`Search ${kind}...`}
              onChange={(_, value) => debouncedSearch(value)}
              onClear={handleClear}
              value={searchTerm}
              style={{
                border: '1px solid #ccc',
                borderRadius: '3px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
              }}
            />
          </InputGroup>
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
  );
};

interface ActionListProps {
  kind: string;
  url?: string;
  reload: number;
  variant?: string;
  borders?: boolean;
  columns: any;
  rows?: any;
  actions?: any;
  mapper?: any;
  toolbarDisabled?: boolean;
  reloadTrigger: number;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  onSearchResults?: (hasResults: boolean) => void;
  isCardView: boolean;
  CardComponent: React.ComponentType<any>;
}

const ActionList: React.FunctionComponent<ActionListProps> = (props) => {
  const [allRows, setAllRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({});
  const [alert, setAlert] = React.useState('');
  const [atitle, setATitle] = React.useState('');
  const conf = React.useContext(GeneralSettingsContext);
  const history = useHistory();
  const [loaded, setLoaded] = React.useState(false);
  const [localSearchTerm, setLocalSearchTerm] = React.useState(props.searchTerm || '');

  const renderCellContent = (cellData) => {
    if (React.isValidElement(cellData)) {
      return cellData;
    }
    if (typeof cellData === 'object' && cellData !== null) {
      if ('title' in cellData && React.isValidElement(cellData.title)) {
        return cellData.title;
      }
      if ('props' in cellData && 'text' in cellData.props) {
        return cellData.props.text;
      }
    }
    return cellData?.toString() || '';
  };

  React.useEffect(() => {
    if (props.url) {
      fetch(props.url, { credentials: 'include' }).then(handleResponse).then(handleData).catch(handleError);
    } else if (props.rows) {
      const mappedData = props.mapper ? props.mapper(props.rows) : props.rows;
      setAllRows(mappedData);
      setFilteredRows(mappedData);
      setLoaded(true);
    }
  }, [props.reloadTrigger, props.url, props.rows, props.mapper]);
  const fetchData = () => {
    if (props.url) {
      fetch(props.url, { credentials: 'include' }).then(handleResponse).then(handleData).catch(handleError);
    } else if (props.rows) {
      setAllRows(props.rows);
      setFilteredRows(props.rows);
      setLoaded(true);
    }
  };

  const handleResponse = React.useCallback((response) => {
    if (response.status === 401) {
      throw new Error('autherror');
    }
    if (!response.ok) {
      throw response;
    }
    return response.json();
  }, []);

  const handleData = React.useCallback((json) => {
    const mappedData = props.mapper(json);
    setAllRows(mappedData);
    setFilteredRows(mappedData);
    setLoaded(true);
  }, [props.mapper]);

  const handleError = (error) => {
    if (error.message === 'autherror') {
      const href = conf.auth + '/login?return_to=' + window.location.href;
      window.location.href = href;
    } else if (typeof error.json === 'function') {
      error.json().then((json) => {
        setATitle('Merge API Error');
        json.url = props.url;
        setAlert(json);
      });
    } else {
      console.log('action list error', error);
      setATitle('Network/Fetch Error');
      setAlert(error);
      history.push('/login');
    }
  };

  React.useEffect(() => {
    if (props.onSearchResults) {
      props.onSearchResults(filteredRows.length > 0);
    }
  }, [filteredRows, props.onSearchResults]);

  React.useEffect(() => {
    filterRows();
  }, [localSearchTerm, allRows]);

  const searchInObject = (obj: any, searchTerm: string): boolean => {
    if (typeof obj !== 'object' || obj === null) {
      return String(obj).toLowerCase().includes(searchTerm);
    }

    return Object.values(obj).some((value) => {
      if (typeof value === 'object' && value !== null) {
        if ('props' in value && 'text' in value.props) {
          return String(value.props.text).toLowerCase().includes(searchTerm);
        }
        if ('title' in value && React.isValidElement(value.title)) {
          return String(value.title.props.children).toLowerCase().includes(searchTerm);
        }
        return searchInObject(value, searchTerm);
      }
      return String(value).toLowerCase().includes(searchTerm);
    });
  };

  const filterRows = React.useMemo(() => {
    return () => {
      if (localSearchTerm && localSearchTerm.trim() !== '') {
        const searchTermLower = localSearchTerm.toLowerCase().trim();
        const filtered = allRows.filter((row) => searchInObject(row, searchTermLower));
        setFilteredRows(filtered);
      } else {
        setFilteredRows(allRows);
      }
    };
  }, [localSearchTerm, allRows]);
  const onSort = React.useCallback((_event, index, direction) => {
    const sortedRows = [...filteredRows].sort((a, b) => {
      const aValue = Object.values(a)[index];
      const bValue = Object.values(b)[index];
      if (typeof aValue === 'object' && aValue !== null && 'title' in aValue) {
        return aValue.props.text.localeCompare(bValue.props.text);
      }
      return aValue.toString().localeCompare(bValue.toString());
    });
    setSortBy({ index, direction });
    setFilteredRows(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse());
  }, [filteredRows]);

    const renderContent = React.useCallback(() => {
      if (!loaded) {
        return (
          <Bullseye>
            <Spinner />
          </Bullseye>
        );
      }
    
      if (filteredRows.length === 0) {
        return (
          <EmptyState>
            <EmptyStateIcon icon={CubesIcon} />
            <Title headingLevel="h4" size="lg">
              No {props.kind} found
            </Title>
            <EmptyStateBody>
              No items match the current filter criteria. Try clearing your filter or changing your search.
            </EmptyStateBody>
          </EmptyState>
        );
      }
    
      return props.isCardView ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {filteredRows.map((row, index) => (
            <props.CardComponent key={index} data={row} actions={props.actions || []} />
          ))}
        </div>
      ) : (
        <Table aria-label={`${props.kind} table`} variant="compact" sortBy={sortBy} onSort={onSort}>
          <Thead>
            <Tr>
              {props.columns.map((column, index) => (
                <Th 
                  key={index}
                  sort={column.transforms?.includes(sortable) ? {
                    sortBy,
                    onSort,
                    columnIndex: index
                  } : undefined}
                >
                  {column.title}
                </Th>
              ))}
              {props.actions && props.actions.length > 0 && <Th>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {filteredRows.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {props.columns.map((column, cellIndex) => {
                  const key = column.key || column.title.toLowerCase().replace(/ /g, '_');
                  const cellContent = renderCellContent(row[key]);
                  return <Td key={cellIndex}>{cellContent}</Td>;
                })}
                {props.actions && props.actions.length > 0 && (
                  <Td>
                    <KebabMenu actions={props.actions} row={row} isCardView={props.isCardView} />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      );
    }, [loaded, filteredRows, props.kind, props.isCardView, props.CardComponent, props.actions, props.columns, renderCellContent, sortBy, onSort]);
  

  return (
    <React.Fragment>
      {alert && (
        <Alert variant="danger" title={atitle}>
          <pre>{JSON.stringify(alert, null, 2)}</pre>
        </Alert>
      )}
      {!props.toolbarDisabled && (
        <ActionListToolbar
          kind={props.kind}
          setSearch={(value) => {
            setLocalSearchTerm(value);
            props.setSearchTerm(value);
          }}
          searchTerm={localSearchTerm}
        />
      )}
      {renderContent()}
    </React.Fragment>
  );
};

export { ActionList };

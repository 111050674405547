import * as React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Split,
  SplitItem,
  Button,
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  Text,
  Flex,
  TextContent,
  Tooltip,
  ButtonVariant,
  FlexItem,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { ActionList } from '@app/lib/ActionList';
import { sortable, headerCol } from '@patternfly/react-table';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { ColorStringFromStatus, LocalizeDate } from '@app/lib/TaskStatusUtils';
import { RebootMaterializationMode, RebootMaterializationRequest } from '@mergetb/api/portal/v1/materialize_types';

import { useTranslation } from 'react-i18next';
import { toTitleCase } from '@app/lib/util';
import { MaterializationCard } from './MaterializationCard';
import { BallotOutlined, GridViewOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { InfoCircleIcon } from '@patternfly/react-icons';
import { eventEmitter } from '@app/Tutorials/EventEmitter';

// Custom styled tooltip
const StyledTooltip = styled(Tooltip)({
  '& .pf-v5-c-tooltip__content': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    border: 'none',
    padding: '10px',
  },
  '& .pf-v5-c-tooltip__arrow': {
    display: 'none',
  },
});

const StyledBallotOutlined = styled(BallotOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const StyledGridViewOutlined = styled(GridViewOutlined)(({ theme }) => ({
  fontSize: '24px',
  color: 'inherit',
  marginRight: '8px',
  verticalAlign: 'middle',
}));

const Materializations: React.FunctionComponent = () => {
  var userview_last = (localStorage.getItem('userview') ?? true) == true;

  const conf = React.useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(1);
  const [alerts, setAlerts] = React.useState([]);
  const { identity } = React.useContext(AuthContext);
  const username = identity?.traits.username;

  const [viewLabel, setViewLabel] = React.useState('View ' + (userview_last ? 'All' : 'Own'));
  const [userView, setUserView] = React.useState(userview_last);

  const { t } = useTranslation();

  const [isCardView, setIsCardView] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [reloadTrigger, setReloadTrigger] = React.useState(0);

  const columns = [
    { title: toTitleCase(t('materialization')), cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Metal Nodes', transforms: [sortable] },
    { title: 'Virtual Nodes', transforms: [sortable] },
    { title: 'Links', transforms: [sortable] },
    { title: 'Ingresses', transforms: [sortable] },
    { title: 'Created', transforms: [sortable] },
    { title: 'Last Updated', transforms: [sortable] },
    { title: 'Status', transforms: [sortable] },
  ];

  const handleViewChange = (newView: 'table' | 'card') => {
    setIsCardView(newView === 'card');
  };

  const handleSearchResults = (hasResults: boolean) => {
    setNoResults(!hasResults);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'success':
        return 'green';
      case 'pending':
        return 'yellow';
      default:
        return 'inherit';
    }
  };

  const mapper = React.useCallback((json) => {
    return json.materializations.map((x, i) => {
      const retVal = {
        metal_nodes: x.numMetal,
        virtual_nodes: x.numVirtual,
        links: x.numLinks,
        ingresses:
          json.ingresses && json.ingresses[i].ingresses
            ? json.ingresses[i].ingresses.map((ing) => `${ing.hostname}:${ing.hostport}`).join(', ')
            : 'N/A',
        created: LocalizeDate(json.statuses[i].FirstUpdated),
        last_updated: LocalizeDate(json.statuses[i].LastUpdated),
        status: (
          <span style={{ color: getStatusColor(json.statuses[i].HighestStatus) }}>
            {json.statuses[i].HighestStatus}
          </span>
        ),
        rid: x.rid,
        eid: x.eid,
        pid: x.pid,
        numMetal: x.numMetal,
        numVirtual: x.numVirtual,
        numLinks: x.numLinks,
        ingressesRaw: json.ingresses && json.ingresses[i].ingresses,
        statusColor: getStatusColor(json.statuses[i].HighestStatus),
        statusText: json.statuses[i].HighestStatus,
        lastUpdated: LocalizeDate(json.statuses[i].LastUpdated),
      };

      retVal[t('materialization').toLowerCase()] = {
        title: <Link to={`/materializations/${x.pid}/${x.eid}/${x.rid}`}>{`${x.rid}.${x.eid}.${x.pid}`}</Link>,
        props: { text: `${x.rid}.${x.eid}.${x.pid}` },
      };

      return retVal;
    });
  }, []);

  const actions = React.useMemo(() => {
    // helper function to reboot mz
    const doMzReboot = (mzid: string, mode: RebootMaterializationMode, message: string) => {
      const [rid, eid, pid] = mzid.split('.');

      const req = RebootMaterializationRequest.fromJSON({
        project: pid,
        experiment: eid,
        realization: rid,
        allNodes: true,
        mode: mode,
      });

      fetch(conf.api + '/materialize/reboot/' + pid + '/' + eid + '/' + rid, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
        })
        .then((json) => {
          addAlert(message, '', 'success');
          setReload(!reload);
        })
        .catch((error) => {
          console.log('got error:', error);
          addAlert('Reboot Error', error.message, 'danger');
        });
    };

    return [
      {
        title: toTitleCase(t('dematerialize')),
        onClick: (event, rowId, rowData) => {
          const [rid, eid, pid] = rowData[t('materialization').toLowerCase()].props.text.split('.');
          fetch(conf.api + '/materialize/materialize/' + pid + '/' + eid + '/' + rid, {
            method: 'DELETE',
            credentials: 'include',
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .then((json) => {
              addAlert('Dematerialization ' + rid + '.' + eid + '.' + pid + ' started.', '', 'success');
              setReload(!reload);
              eventEmitter.emit('dematerialized', { materializationName: `${rid}.${eid}.${pid}` });
            })
            .catch((error) => {
              console.log('got error:', error);
              addAlert('Dematerialize Error', error.message, 'danger');
            });
        },
      },
      {
        isSeparator: true,
      },
      {
        title: 'Warm Reboot',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[t('materialization').toLowerCase()].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Reboot, mzid + ': Warm Reboot requested');
        },
      },
      {
        title: 'Power Cycle',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[t('materialization').toLowerCase()].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Cycle, mzid + ': Power Cycle requested');
        },
      },
      {
        title: 'Reimage',
        onClick: (event, rowID, rowData) => {
          const mzid = rowData[t('materialization').toLowerCase()].props.text;
          doMzReboot(mzid, RebootMaterializationMode.Reimage, mzid + ': Reimage requested');
        },
      },
    ];
  }, [conf.api, t, reload, addAlert]);

  const toggleView = () => {
    setViewLabel('View ' + (userView === false ? 'All' : 'Own'));
    localStorage.setItem('userview', userView ? '0' : '1');
    setUserView(!userView);
    setReload(!reload);
  };

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem>User</BreadcrumbItem>
        <BreadcrumbItem to={'/user/' + username}>{username}</BreadcrumbItem>
        <BreadcrumbItem>{toTitleCase(t('materializations'))}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const header = (
    <PageSection>
      <Split>
        <SplitItem>
          <Flex alignItems={{ default: 'alignItemsCenter' }} style={{ gap: '8px' }}>
            <FlexItem>
              <Title headingLevel="h1" size="2xl">
                {toTitleCase(t('materializations'))}
              </Title>
            </FlexItem>
            <FlexItem>
              <StyledTooltip
                content={
                  <TextContent>
                    <Text>
                      A materialization is a physical instantiation of a realization on the resource substrates. When a
                      materialization is created, virtual machine and bare metal nodes are imaged with OSes, and virtual
                      networks are synthesized on node and switches to create the requested experiment topology.
                    </Text>
                  </TextContent>
                }
                position="right"
              >
                <Button variant="plain" aria-label="More info for Materializations" style={{ padding: 0 }}>
                  <InfoCircleIcon />
                </Button>
              </StyledTooltip>
            </FlexItem>
          </Flex>
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button variant="control" aria-label={viewLabel} onClick={toggleView}>
            {viewLabel}
          </Button>
        </SplitItem>
      </Split>
    </PageSection>
  );

  const addAlert = (t, m, v) => {
    setAlerts((prev) => [...prev, { title: t, message: m, variant: v, key: new Date().getTime() }]);
  };

  const removeAlert = (key) => {
    setAlerts([...alerts.filter((el) => el.key !== key)]);
  };

  const notifications = (
    <AlertGroup isToast>
      {alerts.map((a, i) => (
        <Alert
          isExpandable={a.message !== ''}
          variant={a.variant}
          title={a.title}
          key={a.key}
          timeout={8000}
          actionClose={
            <AlertActionCloseButton
              title={a.title}
              variantLabel={`${a.variant} alert`}
              onClose={() => removeAlert(a.key)}
            />
          }
        >
          {a.message}
        </Alert>
      ))}
    </AlertGroup>
  );

  let url = conf.api + '/materializev2/materializations';
  if (userView === false) {
    url += '?filter=ByAll';
  }

  return (
    <React.Fragment>
      {alerts.length !== 0 && notifications}
      {crumbs}
      {header}
      <PageSection>
        <Flex alignItems={{ default: 'alignItemsCenter' }} spaceItems={{ default: 'spaceItemsMd' }}>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.control : ButtonVariant.primary}
              onClick={() => handleViewChange('table')}
              aria-label="Table view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledBallotOutlined />
              <span style={{ lineHeight: 1 }}>Table</span>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              variant={isCardView ? ButtonVariant.primary : ButtonVariant.control}
              onClick={() => handleViewChange('card')}
              aria-label="Card view"
              style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <StyledGridViewOutlined />
              <span style={{ lineHeight: 1 }}>Card</span>
            </Button>
          </FlexItem>
        </Flex>
        <div style={{ overflowX: 'auto', marginTop: '16px' }}>
          <ActionList
            kind="Materializations"
            columns={columns}
            url={url}
            actions={actions}
            mapper={mapper}
            reload={reload}
            reloadTrigger={reloadTrigger}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchResults={handleSearchResults}
            isCardView={isCardView}
            CardComponent={MaterializationCard}
          />
        </div>
        {noResults && (
          <Alert variant="info" title="No materializations found" isInline>
            No materializations match your search criteria. Try adjusting your search.
          </Alert>
        )}
      </PageSection>
    </React.Fragment>
  );
};

export { Materializations };
